import React, { BaseSyntheticEvent, FC } from 'react'
import { UseFormMethods } from 'react-hook-form'

import FoodCategoriesSelect from './FoodCategoriesSelect'
import { FoodUnitMappingInput } from './FoodUnitMappingInput'

import {
  Button,
  FormInputAutocompleteController,
  FormInputNumberController,
  FormInputText,
  ImageUploadController,
  RichTextInputController
} from 'Components'
import Tooltip from 'Components/Tooltip/Tooltip'
import { constants } from 'Config'
import { EatableSource } from 'Models'
import { ImageUploadingFormProps } from 'Utils/helpers/useImageUploading'

export type FoodFormProps = Pick<UseFormMethods, 'register' | 'errors' | 'setValue' | 'setError' | 'control'> &
  ImageUploadingFormProps & {
    isSubmitting: boolean
    eatableSources: EatableSource[]
    onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
    onCancel?: () => void
  }

export const FoodForm: FC<FoodFormProps> = ({
  setTempImageId,
  setIsImageUploading,
  isImageUploading,
  isSubmitting,
  onSubmit,
  onCancel,
  eatableSources,
  ...reactHookFormProps
}: FoodFormProps) => {
  const areButtonsDisabled = isSubmitting || isImageUploading

  const sortAlphabetically = (a: { name: string }, b: { name: string }): number => a.name.localeCompare(b.name)

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="row mt-0">
        <div className="col-12">
          <h4>Daten</h4>
        </div>
        <div className="col-12">
          <FormInputText label="Name" name="name" {...reactHookFormProps} />
        </div>
        <FoodCategoriesSelect {...reactHookFormProps} />
        <FormInputAutocompleteController
          name="source"
          label="Quelle"
          allowNewItem
          items={eatableSources}
          sortItemsFunction={sortAlphabetically}
          {...reactHookFormProps}
        />
        <div className="col-12">
          <FormInputText label="Strichcode Nummer" name="barcodes" {...reactHookFormProps} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Foto</h4>
        </div>
        <div className="col-12">
          <ImageUploadController
            name="imageUrl"
            path={constants.imageServiceFolders.userFoodPictures}
            theme="rectangle"
            onIdChange={setTempImageId}
            onUploadingChange={setIsImageUploading}
            {...reactHookFormProps}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>
            Welche Nährwerte hat das Nahrungsmittel?
            <Tooltip>
              Notieren Sie die einzelnen Nährwerte. Diese finden Sie in der Nährwerttabelle auf der Verpackung. Bitte
              notieren Sie die Nährwerte pro 100&#8239;g.
            </Tooltip>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Kalorien"
            name="energy"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Fett"
            name="fat"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Kohlenhydrate"
            name="carbohydrates"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Zucker"
            name="sugar"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Nahrungsfasern"
            name="fibre"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Protein"
            name="protein"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Salz"
            name="salt"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Alkohol"
            name="alcohol"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Wie hoch ist der Anteil an Gemüse und/oder Früchten im Nahrungsmittel?</h4>
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Gemüse"
            name="vegetable"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInputNumberController
            label="Früchte / Obst"
            name="fruit"
            maxFractions={1}
            unitLabel="pro 100g"
            {...reactHookFormProps}
          />
        </div>
        {reactHookFormProps.errors.vegetablesAndFruitMax && (
          <div className="input-error">{reactHookFormProps.errors.vegetablesAndFruitMax?.message}</div>
        )}
      </div>

      <div className="row">
        <div className="col-12">
          <h4>
            Um welche Menge handelt es sich?
            <Tooltip>
              Wählen Sie eine Menge aus, wenn der Standard nicht Gramm ist. Zum Beispiel: 1 Riegel mit 65&#8239;g für
              einen Nussriegel oder 1 Becher mit 180&#8239;g für ein Joghurt.
            </Tooltip>
          </h4>
        </div>
        <FoodUnitMappingInput {...reactHookFormProps} />
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Beschreibung</h4>
        </div>
        <div className="col-12">
          <RichTextInputController name="description" {...reactHookFormProps} />
        </div>
      </div>

      <div className="row">
        <div className="col-6 col-lg-4">
          <Button type="button" theme="secondary" disabled={areButtonsDisabled} onClick={onCancel}>
            Abbrechen
          </Button>
        </div>
        <div className="col-6 col-lg-4 offset-lg-4">
          <Button type="submit" disabled={areButtonsDisabled}>
            Speichern
          </Button>
        </div>
      </div>
    </form>
  )
}
