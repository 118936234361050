import { AnyAction, combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { FETCH_FITNESS_PLAN, FETCH_FITNESS_PLAN_FULFILLED } from './actionTypes'
import { createPendingCounterReducer } from './common'

import {
  FetchFitnessPlanPayload,
  FitnessPlanReduxState,
  FitnessPlanSlot,
  FitnessPlanWeek,
  PromiseAction,
  ReduxStoreState
} from 'Models'
import { coreBackendService } from 'Services'

export const fetchFitnessPlanAction = (payload: FetchFitnessPlanPayload): PromiseAction<FitnessPlanWeek> => ({
  type: FETCH_FITNESS_PLAN,
  payload: coreBackendService.fetchFitnessplan(payload)
})

const initialState: FitnessPlanWeek = []

const sortFitnessPlans = (fitnessPlans: FitnessPlanWeek): FitnessPlanWeek => {
  return fitnessPlans
    .slice()
    .sort((a, b) => a.id.localeCompare(b.id))
    .map((fitnessPlan) => ({
      ...fitnessPlan,
      slots: fitnessPlan.slots.slice().sort((a, b) => a.rank - b.rank)
    }))
}

export const getDayOffset = (weekDay: string): number => {
  const daySequence: { [key: string]: number } = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6
  }

  return daySequence[weekDay]
}

const getFitnessPlanSlotsForDate = (fitnessPlans: FitnessPlanWeek, targetDate: Date) => {
  const matchingSlots: FitnessPlanSlot[] = []

  fitnessPlans.forEach((plan) => {
    const startDate = new Date(plan.startDate)
    const startDayOfWeek = startDate.getDay()

    plan.slots.forEach((slot) => {
      const daysFromStartToSlot = (slot.week - 1) * 7 + getDayOffset(slot.weekDay) - startDayOfWeek

      const slotDate = new Date(startDate)
      slotDate.setDate(slotDate.getDate() + daysFromStartToSlot)

      if (
        slotDate.toDateString() === targetDate.toDateString() ||
        (!plan.endDate && getDayOffset(slot.weekDay) === targetDate.getDay())
      ) {
        matchingSlots.push(slot)
      }
    })
  })

  return matchingSlots
}

const data = (state = initialState, { type, payload }: AnyAction): FitnessPlanWeek => {
  switch (type) {
    case FETCH_FITNESS_PLAN_FULFILLED: {
      return payload
    }

    default:
      return state
  }
}

export const fitnessPlanWeekSelector = createSelector(
  [(state: ReduxStoreState) => state.fitnessPlan.data],
  (fitnessPlans) => sortFitnessPlans(fitnessPlans)
)

export const fitnessPlanSlotsForDateSelector = (targetDate: Date) =>
  createSelector([fitnessPlanWeekSelector], (fitnessPlans) =>
    getFitnessPlanSlotsForDate(sortFitnessPlans(fitnessPlans), targetDate)
  )

const asyncActionTypes = [FETCH_FITNESS_PLAN]

export default combineReducers<FitnessPlanReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
