import React, { FC, useEffect, useMemo, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import JournalDay from './components/JournalDay'
import EnergyModal from './components/Modals/EnergyModal'
import EnergyMotionModal from './components/Modals/EnergyMotionModal'
import JournalFavoriteTabsModal from './components/Modals/JournalFavoriteTabsModal/JournalFavoriteTabsModal'

import {
  Cockpit,
  DatePickerWithNavigation,
  FormInputToggle,
  Icon,
  IconColor,
  IconName,
  PageLayout,
  SearchModal
} from 'Components'
import {
  JournalEnergyMotionRecord,
  JournalEnergyRecord,
  JournalMotionRecord,
  Nutritions,
  QuantifiedEatable,
  ReduxStoreState,
  UserSettings
} from 'Models'
import {
  getFlattenedJournalEatableRecordsContainers,
  journalEatableRecordContainersForDate,
  menuPlanContainersForDate
} from 'Pages/MenuPlanPage/journalEatableCardContainersForDate'
import { currentWeightSelector, fetchBodyMeasurementsAction } from 'ReduxStore/bodyMeasurements'
import { isLoadingSelector } from 'ReduxStore/common'
import { dayJournalCardsCombinedLoadingSelector } from 'ReduxStore/common/combinedSelectors'
import {
  fetchUserGoalNutritionsAction,
  registrationDateSelector,
  userGoalNutritionsCurrentDateSelector
} from 'ReduxStore/coreBackendUser'
import { fetchFitnessPlanAction, fitnessPlanSlotsForDateSelector } from 'ReduxStore/fitnessPlan'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { currentJournalDateSelector, setJournalDateAction } from 'ReduxStore/journalDay'
import { dayJournalEatableRecordsSelector, fetchJournalEatableRecordsAction } from 'ReduxStore/journalEatableRecords'
import {
  addJournalEnergyMotionRecordAction,
  dayJournalEnergyMotionRecordsSelector,
  fetchJournalEnergyMotionRecordsAction,
  journalEnergyMotionRecordsDataSelector
} from 'ReduxStore/journalEnergyMotionRecords'
import {
  addJournalEnergyRecordAction,
  dayJournalEnergyRecordsSelector,
  fetchJournalEnergyRecordsAction,
  journalEnergyRecordsDataSelector
} from 'ReduxStore/journalEnergyRecords'
import { fetchJournalHydrationGoal, fetchJournalHydrationRecords } from 'ReduxStore/journalHydrationRecordsSlice'
import {
  dayJournalMotionRecordsSelector,
  fetchJournalMotionRecordsAction,
  journalMotionRecordsDataSelector
} from 'ReduxStore/journalMotionRecords'
import { fetchMenuPlanAction, menuPlanNewDaysSelector } from 'ReduxStore/menuPlan'
import { denyAccessPaymentRedirectPageAfterProlongingMembership } from 'ReduxStore/temporaryAccessSlice'
import { updateUserSettingsAction, userSettingsDataSelector } from 'ReduxStore/userSettings'
import { Calculator } from 'Utils'

type SelectorProps = {
  journalEnergyMotionRecords: JournalEnergyMotionRecord[]
  journalEnergyRecords: JournalEnergyRecord[]
  journalMotionRecords: JournalMotionRecord[]
  dayJournalEnergyMotionRecords: JournalEnergyMotionRecord[]
  dayJournalEnergyRecords: JournalEnergyRecord[]
  dayJournalMotionRecords: JournalMotionRecord[]
  date: Date
  weight: number
  isUserLoading: boolean
  isMenuPlanLoading: boolean
  isJournalDayLoading: boolean
  userSettings: UserSettings
  goalNutritions: Nutritions
  registrationDate: Date
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  journalEnergyMotionRecords: journalEnergyMotionRecordsDataSelector,
  journalEnergyRecords: journalEnergyRecordsDataSelector,
  journalMotionRecords: journalMotionRecordsDataSelector,
  dayJournalEnergyMotionRecords: dayJournalEnergyMotionRecordsSelector,
  dayJournalEnergyRecords: dayJournalEnergyRecordsSelector,
  dayJournalMotionRecords: dayJournalMotionRecordsSelector,
  date: currentJournalDateSelector,
  weight: currentWeightSelector,
  isUserLoading: (state) => isLoadingSelector(state.coreBackendUser),
  isMenuPlanLoading: (state) => isLoadingSelector(state.menuPlan),
  isJournalDayLoading: dayJournalCardsCombinedLoadingSelector,
  userSettings: userSettingsDataSelector,
  goalNutritions: userGoalNutritionsCurrentDateSelector,
  registrationDate: registrationDateSelector
})

const mapDispatchToProps = {
  fetchJournalEatableRecords: fetchJournalEatableRecordsAction,
  fetchJournalMotionRecords: fetchJournalMotionRecordsAction,
  fetchJournalEnergyMotionRecords: fetchJournalEnergyMotionRecordsAction,
  fetchJournalEnergyRecords: fetchJournalEnergyRecordsAction,
  addJournalEnergyMotionRecord: addJournalEnergyMotionRecordAction,
  addJournalEnergyRecord: addJournalEnergyRecordAction,
  setJournalDate: setJournalDateAction,
  fetchBodyMeasurements: fetchBodyMeasurementsAction,
  updateUserSettings: updateUserSettingsAction,
  fetchUserGoalNutritions: fetchUserGoalNutritionsAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const JournalPage: FC<PropsFromRedux> = ({
  fetchJournalEatableRecords,
  fetchJournalEnergyMotionRecords,
  fetchJournalEnergyRecords,
  fetchJournalMotionRecords,
  date,
  setJournalDate,
  weight,
  dayJournalEnergyMotionRecords,
  dayJournalEnergyRecords,
  dayJournalMotionRecords,
  addJournalEnergyMotionRecord,
  addJournalEnergyRecord,
  fetchBodyMeasurements,
  updateUserSettings,
  fetchUserGoalNutritions,
  goalNutritions: { energy: userGoalEnergy = 0 },
  goalNutritions,
  userSettings: { menuPlanHidden },
  isUserLoading,
  isMenuPlanLoading,
  isJournalDayLoading,
  registrationDate
}: PropsFromRedux) => {
  const dispatch = useAppDispatch()
  const [showEnergyModal, setShowEnergyModal] = useState<boolean>(false)
  const [showEnergyMotionModal, setShowEnergyMotionModal] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [showFavoriteTabsModal, setShowFavoriteTabsModal] = useState(false)
  const currentJournalDate = useAppSelector(currentJournalDateSelector)
  const menuPlanDays = useAppSelector(menuPlanNewDaysSelector)
  const fitnessPlanDay = useAppSelector(fitnessPlanSlotsForDateSelector(currentJournalDate))

  const dayJournalEatableRecords = useAppSelector(dayJournalEatableRecordsSelector)

  const menuPlanContainers = menuPlanContainersForDate({
    menuPlanDays: menuPlanDays,
    journalEatableRecordsForDate: dayJournalEatableRecords,
    date: currentJournalDate
  })

  const journalEatableRecordContainers = journalEatableRecordContainersForDate({
    journalEatableRecordsForDate: dayJournalEatableRecords
  })

  const flattenedJournalEatableRecordsContainers = getFlattenedJournalEatableRecordsContainers({
    journalEatableRecordContainers: journalEatableRecordContainers
  })

  useEffect(() => {
    fetchBodyMeasurements()
  }, [])

  useEffect(() => {
    fetchUserGoalNutritions(date)
    fetchJournalEatableRecords(date)
    fetchJournalEnergyMotionRecords(date)
    dispatch(fetchMenuPlanAction({ date: currentJournalDate, includeWeek: false }))
    dispatch(fetchFitnessPlanAction({ date: currentJournalDate }))
    fetchJournalEnergyRecords(date)
    fetchJournalMotionRecords(date)
    dispatch(fetchJournalHydrationGoal(date))
    dispatch(fetchJournalHydrationRecords(date))
  }, [date.toISOString()])

  useEffect(() => {
    dispatch(denyAccessPaymentRedirectPageAfterProlongingMembership())
  })

  const onToggleEnergyModal = (): void => setShowEnergyModal(!showEnergyModal)
  const onToggleEnergyMotionModal = (): void => setShowEnergyMotionModal(!showEnergyMotionModal)

  const onChangeDate = (date: Date | null): void => {
    if (date) {
      setJournalDate(date)
    }
  }

  const acceptedEnergyMotionSum = (): number => {
    const acceptedMotionEnergy = Calculator.sumEnergy(
      dayJournalMotionRecords.map((jmr) => ({ energy: Calculator.calculateJournalMotionRecordEnergy(jmr, weight) }))
    )
    const acceptedDirectEnergyMotionIntake = Calculator.sumEnergy(dayJournalEnergyMotionRecords)
    return (acceptedMotionEnergy || 0) + acceptedDirectEnergyMotionIntake
  }

  const renderedJournalDay = useMemo(() => {
    const sumCards =
      dayJournalEnergyRecords.length +
      dayJournalEnergyMotionRecords.length +
      dayJournalMotionRecords.length +
      flattenedJournalEatableRecordsContainers.length

    return (
      <>
        {isMenuPlanLoading && (
          <div className="text-center">
            <p>Menüplan wird geladen …</p>
          </div>
        )}
        {isJournalDayLoading && sumCards === 0 ? (
          <div className="text-center">
            <p>Tagebuch wird geladen …</p>
          </div>
        ) : (
          <JournalDay
            journalEatableRecordContainers={journalEatableRecordContainers}
            menuPlanContainers={menuPlanContainers}
            flattenedJournalEatableRecordsContainers={flattenedJournalEatableRecordsContainers}
            journalEnergyMotionRecords={dayJournalEnergyMotionRecords}
            journalEnergyRecords={dayJournalEnergyRecords}
            journalMotionRecords={dayJournalMotionRecords}
            fitnessPlanSlots={fitnessPlanDay}
            menuPlanHidden={!!menuPlanHidden}
            weight={weight}
            acceptedEnergyMotionSum={acceptedEnergyMotionSum()}
          />
        )}
      </>
    )
  }, [
    isMenuPlanLoading,
    isJournalDayLoading,
    flattenedJournalEatableRecordsContainers,
    dayJournalEnergyMotionRecords,
    dayJournalEnergyRecords,
    dayJournalMotionRecords,
    menuPlanHidden
  ])

  const renderFavoriteTabModal = useMemo(
    () => <JournalFavoriteTabsModal isOpen={showFavoriteTabsModal} onClose={() => setShowFavoriteTabsModal(false)} />,
    [showFavoriteTabsModal]
  )

  const renderedEnergyModal = useMemo(
    () => (
      <EnergyModal
        isOpen={showEnergyModal}
        onToggle={onToggleEnergyModal}
        addJournalEnergyRecord={(description, energy, mealCategory) =>
          addJournalEnergyRecord(description, energy, mealCategory, date)
        }
        resetOnToggle
      />
    ),
    [showEnergyModal, date]
  )

  const renderedEnergyMotionModal = useMemo(
    () => (
      <EnergyMotionModal
        isOpen={showEnergyMotionModal}
        onToggle={onToggleEnergyMotionModal}
        addJournalEnergyMotionRecord={(description, energy, duration) =>
          addJournalEnergyMotionRecord(description, energy, duration, date)
        }
        resetOnToggle
      />
    ),
    [showEnergyMotionModal, date]
  )

  const onToggleMenuPlan = (): void => {
    updateUserSettings({ menuPlanHidden: !menuPlanHidden })
  }

  const renderedMenuplanToggle = useMemo(
    () => (
      <div className="my-4">
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">Menüplan:</div>
          <FormInputToggle value={!menuPlanHidden} onValueChange={onToggleMenuPlan} />
        </div>
      </div>
    ),
    [menuPlanHidden]
  )

  const renderedDatePickerNavigation = useMemo(
    () => (
      <DatePickerWithNavigation currentDate={date} onChange={onChangeDate} rangeAllowed={{ from: registrationDate }} />
    ),
    [date]
  )

  const onToggleSearch = (): void => setShowSearch(!showSearch)

  const renderedSearchTrigger = useMemo(
    () => (
      <div className="row g-1">
        <div className="col-9">
          <div className="journalentrypane is-search" onClick={onToggleSearch}>
            <Icon name={IconName.search} color={IconColor.BLUE_2} />
            <span>Mahlzeit oder Aktivität mit der Suche hinzufügen</span>
          </div>
        </div>
        <div className="col-3">
          <div className="journalentrypane is-favorite" onClick={() => setShowFavoriteTabsModal(true)}>
            <Icon name={IconName.heart} color={IconColor.BLUE_2} />
            <span>Favoriten</span>
          </div>
        </div>
        <div className="col-6">
          <div className="journalentrypane" onClick={onToggleEnergyModal}>
            <Icon name={IconName.utensils} color={IconColor.GRAY_1} />
            <span>Mahlzeit ohne Suche eintragen</span>
          </div>
        </div>
        <div className="col-6">
          <div className="journalentrypane" onClick={onToggleEnergyMotionModal}>
            <Icon name={IconName.weight} color={IconColor.GRAY_1} />
            <span>Aktivität ohne Suche eintragen</span>
          </div>
        </div>
      </div>
    ),
    [onToggleSearch]
  )

  const renderedSearchModal = useMemo(() => <SearchModal isOpen={showSearch} onClose={onToggleSearch} />, [
    showSearch,
    onToggleSearch
  ])

  const visibleJournalEatableRecords = useMemo((): QuantifiedEatable[] => {
    return flattenedJournalEatableRecordsContainers
      .filter((card) => card.journalRecordId && card.journalRecordId.length > 0)
      .map(({ eatableCardObjects }) => {
        return { ...eatableCardObjects[0].eatable }
      })
  }, [menuPlanContainers])

  const renderedCockpit = useMemo(
    () => (
      <Cockpit
        date={date}
        isLoading={isUserLoading}
        acceptedNutritions={Calculator.sumNutritionsOfQuantfiedEatables(...visibleJournalEatableRecords)}
        acceptedDirectEnergyIntake={Calculator.sumEnergy(dayJournalEnergyRecords)}
        goalNutritions={goalNutritions}
        goalEnergy={userGoalEnergy}
        acceptedEnergyMotionSum={acceptedEnergyMotionSum()}
      />
    ),
    [
      date,
      isUserLoading,
      visibleJournalEatableRecords,
      goalNutritions,
      dayJournalEnergyRecords,
      dayJournalEnergyMotionRecords,
      dayJournalMotionRecords
    ]
  )

  return (
    <PageLayout theme="largecircle">
      <div className="container max-700">
        {renderedCockpit}
        {renderedDatePickerNavigation}
        {renderedSearchTrigger}
        {renderedMenuplanToggle}
        {renderedJournalDay}
      </div>
      <div className="container">
        {renderedSearchModal}
        {renderedEnergyModal}
        {renderedEnergyMotionModal}
        {renderFavoriteTabModal}
      </div>
    </PageLayout>
  )
}

export default connector(JournalPage)
