import _ from 'lodash'
import React, { useCallback, useState } from 'react'

import { CardIconButton, IconButton } from '../IconButton'

import { IconColor, IconName } from 'Components/Icon'
import sourceStyles from 'Components/JournalCardSearchBase/JournalCardSearchBase.module.css'
import { FavoriteIcon } from 'Components/common/FavoriteIcon'
import { ImageUrl } from 'Models'
import { ZIndexPriority } from 'Utils/zIndexPriority'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Callback = (event?: any) => void

export type ResponsiveCardBaseProps = {
  title?: string
  details?: string
  className?: string
  imageUrl?: ImageUrl
  svgUrl?: string | null
  showCheckbox?: boolean
  onClick?: Callback
  toggleChildrenIsOn?: boolean
  source?: string
  iconButtons: CardIconButton[]
  cardButtons: JSX.Element[]
  isFavorite?: boolean
  isInFitnessPlan?: boolean
  isAccepted?: boolean
}

export const ResponsiveCardBase: React.FC<ResponsiveCardBaseProps> = ({
  title,
  details,
  className,
  imageUrl = null,
  showCheckbox = false,
  iconButtons,
  cardButtons,
  isFavorite = false,
  isInFitnessPlan = false,
  toggleChildrenIsOn,
  source = '',
  onClick,
  children
}) => {
  const handleCardClick = useCallback(() => {
    onClick && onClick()
  }, [onClick])

  const [toggleMenu, setToggleMenu] = useState(false)

  const clazzName = _.compact(['standardcard', className]).join(' ')

  return (
    <div className={clazzName}>
      <div style={{ position: 'absolute', top: -7, left: -10, zIndex: ZIndexPriority.LOW }}>
        <FavoriteIcon isFavorite={isFavorite} />
      </div>
      <div className="standardcard-image" onClick={handleCardClick}>
        {imageUrl && <img src={`${imageUrl}&width=120`} alt={title} />}
        {showCheckbox && <div className="standardcard-label">Menüplan</div>}
        {isInFitnessPlan && <div className="standardcard-label is-fitness-plan">Fitnessplan</div>}
      </div>
      <div className="standardcard-content" onClick={handleCardClick}>
        {title}
        <span className={`${sourceStyles.source} ${sourceStyles.sourceWidth}`}>{source}</span>
        {details && <span>{details}</span>}
      </div>
      <div tabIndex={0} onBlur={() => setToggleMenu(false)} className="standardcard-icons">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
          {cardButtons.map((icon) => icon)}
        </div>
        <IconButton name={IconName.menuv} color={IconColor.GRAY_2} onClick={() => setToggleMenu(!toggleMenu)} />
        {toggleMenu && (
          <ul className="standardcard-dropdown">
            {iconButtons.map((btn, i) => (
              <li key={i} onClick={() => setToggleMenu(!toggleMenu)}>
                <IconButton name={btn.name} color={btn.color} label={btn.label} onClick={btn.onClick} />
              </li>
            ))}
          </ul>
        )}
      </div>
      {toggleChildrenIsOn ? children : null}
    </div>
  )
}
