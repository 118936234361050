import { EatableSource, Food, FoodCategory, Nutritions, ReduxFoodAddData, UnitId } from 'Models'

export type FoodFormValuesType = Partial<Nutritions> & {
  name: string
  imageUrl: string
  foodCategoryId: string
  foodSubCategoryId: string
  description: string
  barcodes: string
  source?: EatableSource | null
  mealCategories: string[]
  foodCriteriaNormal: string[]
  foodCriteriaSpecial: string[]
  unit?: UnitId
  gramsPerUnit?: number
  vegetable?: number
  fruit?: number
}

export const foodFormInitialValues = {
  name: '',
  imageUrl: '',
  sourceId: '',
  foodCategoryId: '',
  foodSubCategoryId: '',
  description: '',
  barcodes: '',
  mealCategories: [],
  foodCriteriaNormal: [],
  foodCriteriaSpecial: [],
  unit: undefined
}

export const mapValuesToFood = ({
  values: {
    name,
    description,
    energy = 0,
    carbohydrates = 0,
    protein = 0,
    fat = 0,
    fibre,
    alcohol,
    salt = 0,
    sugar = 0,
    barcodes,
    unit,
    gramsPerUnit,
    foodSubCategoryId,
    imageUrl,
    source,
    vegetable,
    fruit
  }
}: {
  values: FoodFormValuesType
}): ReduxFoodAddData => {
  return {
    name,
    description,
    imageUrl,
    source: source ?? undefined,
    foodCategoryId: foodSubCategoryId,
    ...(gramsPerUnit && unit
      ? {
          unitMappings: [
            {
              gramsPerUnit,
              unit
            }
          ]
        }
      : {}),
    nutritions: { energy, carbohydrates, protein, fat, fibre, alcohol, salt, sugar },
    fruit,
    vegetable,
    foodCriteria: [],
    eans: barcodes.split(',')
  }
}

export const mapFoodToValues = (
  {
    name,
    description,
    imageUrl,
    sourceId,
    foodCategoryId: foodSubCategoryId,
    unitMappings,
    nutritions: { energy, carbohydrates, protein, fat, fibre, alcohol, salt, sugar } = {} as Nutritions,
    eans = [],
    vegetable,
    fruit
  }: Food,
  foodCategories: FoodCategory[]
): FoodFormValuesType => {
  return {
    name,
    description: description || '',
    energy: energy || 0,
    carbohydrates: carbohydrates || 0,
    protein: protein || 0,
    fat: fat || 0,
    fibre: fibre ? fibre : fibre === 0 ? fibre : undefined,
    alcohol: alcohol ? alcohol : alcohol === 0 ? alcohol : undefined,
    salt: salt || 0,
    sugar: sugar || 0,
    unit: unitMappings && unitMappings.length > 0 ? unitMappings[0].unit : undefined,
    gramsPerUnit: unitMappings && unitMappings.length > 0 ? unitMappings[0].gramsPerUnit : undefined,
    foodCategoryId:
      {
        ...foodCategories.find((cat) => cat.subCategories?.some((subcat) => subcat.id === foodSubCategoryId))
      }.id || '',
    foodSubCategoryId: foodSubCategoryId || '',
    imageUrl: imageUrl || '',
    // ToDo: Resolving of sourceId to a source object should rather be handled by the redux layer
    source: sourceId
      ? {
          id: sourceId,
          name: '' // only the id is relevant for th InputAutocomplete to find the default value in the food form
        }
      : null,
    barcodes: eans.join(','),
    mealCategories: [],
    foodCriteriaNormal: [],
    foodCriteriaSpecial: [],
    vegetable,
    fruit
  }
}
