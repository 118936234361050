import { yupNumberTransformer } from 'Utils'
import yup from 'yup-schema'

const percentageInfo = 'Bitte geben Sie eine Prozentzahl (0–100) ein.'
const vegetablesAndFruitMaxInfo = 'Die Summe der der eingegebenen Werte überschreitet 100 Prozent.'

export const foodFormValidationSchema = yup
  .object({
    name: yup.string().required(),
    sourceId: yup.string(),
    barcodes: yup.string(),
    foodCategoryId: yup.string().required(),
    foodSubCategoryId: yup.string().required(),
    energy: yup.number().transform(yupNumberTransformer).required(),
    protein: yup.number().transform(yupNumberTransformer).required(),
    carbohydrates: yup.number().transform(yupNumberTransformer).required(),
    fat: yup.number().transform(yupNumberTransformer).required(),
    salt: yup.number().transform(yupNumberTransformer),
    sugar: yup.number().transform(yupNumberTransformer),
    fibre: yup.number().transform(yupNumberTransformer),
    alcohol: yup.number().transform(yupNumberTransformer),
    fruit: yup.number().transform(yupNumberTransformer).min(0, percentageInfo).max(100, percentageInfo),
    vegetable: yup.number().transform(yupNumberTransformer).min(0, percentageInfo).max(100, percentageInfo),
    unit: yup.string(),
    gramsPerUnit: yup.number().transform(yupNumberTransformer)
  })
  .test({
    name: 'vegetablesAndFruitMax',
    message: vegetablesAndFruitMaxInfo,
    test: function (value) {
      return (value.fruit ?? 0) + (value.vegetable ?? 0) <= 100
    }
  })
