import dayjs from 'dayjs'
import _ from 'lodash'
import { AnyAction, combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  ADD_JOURNAL_EATABLE_RECORD,
  ADD_JOURNAL_EATABLE_RECORD_FULFILLED,
  ADD_JOURNAL_EATABLE_RECORD_PENDING,
  ADD_JOURNAL_EATABLE_RECORD_REJECTED,
  FETCH_JOURNAL_EATABLE_RECORDS,
  FETCH_JOURNAL_EATABLE_RECORDS_FULFILLED,
  REMOVE_JOURNAL_EATABLE_RECORD,
  REMOVE_JOURNAL_EATABLE_RECORD_PENDING,
  REMOVE_JOURNAL_EATABLE_RECORD_REJECTED,
  UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT,
  UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_PENDING,
  UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_REJECTED,
  UPDATE_JOURNAL_EATABLE_RECORD_DATETIME,
  UPDATE_JOURNAL_EATABLE_RECORD_DATETIME_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'
import { currentJournalDateSelector } from './journalDay'

import { today } from 'Config'
import {
  Amount,
  JournalEatableRecord,
  JournalEatableRecordsReduxState,
  MealCategory,
  PromiseAction,
  QuantifiedEatable,
  ReduxStoreState,
  ThunkAction,
  UnitId
} from 'Models'
import { coreBackendService } from 'Services'
import { Calculator, Utils } from 'Utils'

// Helper functions
const findJournalEatableRecord = (
  journalEatableRecords: JournalEatableRecord[],
  date: Date,
  mealCategory: MealCategory,
  quantifiedEatable: QuantifiedEatable
): JournalEatableRecord | undefined => {
  return journalEatableRecords
    ? journalEatableRecords.find(
        (record) =>
          dayjs(record.datetime).isSame(date, 'day') &&
          record.mealCategory === mealCategory &&
          !record.source?.includes('menu-plan') &&
          ((quantifiedEatable.food && record.foodId === quantifiedEatable.food.id) ||
            (quantifiedEatable.recipe && record.recipeId === quantifiedEatable.recipe.id))
      )
    : undefined
}

// Reducer
const data = (state: JournalEatableRecord[] = [], { type, payload, meta }: AnyAction): JournalEatableRecord[] => {
  switch (type) {
    case FETCH_JOURNAL_EATABLE_RECORDS_FULFILLED:
      return Utils.unique<JournalEatableRecord>([...state, ...payload])
    case ADD_JOURNAL_EATABLE_RECORD_PENDING:
      return [...state, { ...meta.data.quantifiedEatable, ...meta.data }]
    case ADD_JOURNAL_EATABLE_RECORD_FULFILLED:
      return [...state, _.merge({ ...meta.data.quantifiedEatable }, payload)].filter(
        (record: JournalEatableRecord) => record.id !== meta.data.id
      )
    case ADD_JOURNAL_EATABLE_RECORD_REJECTED:
    case REMOVE_JOURNAL_EATABLE_RECORD_PENDING:
      return state.filter((record: JournalEatableRecord) => record.id !== meta.data.id)
    case REMOVE_JOURNAL_EATABLE_RECORD_REJECTED:
      return [...state, meta.data]
    case UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_PENDING:
      return state.map((record: JournalEatableRecord) =>
        record.id === meta.data.id ? { ...record, ...meta.data } : record
      )
    case UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_REJECTED:
      return state.map((record: JournalEatableRecord) =>
        record.id === meta.data.id ? { ...record, unit: meta.oldUnit, quantity: meta.oldQuantity } : record
      )
    case UPDATE_JOURNAL_EATABLE_RECORD_DATETIME_FULFILLED:
      return state.map((record: JournalEatableRecord) =>
        record.id === meta.data.id ? { ...record, ...meta.data } : record
      )
    default:
      return state
  }
}

// Actions
export const fetchJournalEatableRecordsAction = (date: Date): PromiseAction<JournalEatableRecord[]> => ({
  type: FETCH_JOURNAL_EATABLE_RECORDS,
  payload: coreBackendService.fetchJournalEatableRecords({ date })
})

export const updateJournalEatableRecordAction = (
  recordUpdates: JournalEatableRecord,
  oldValues: { oldUnit: UnitId | undefined; oldQuantity: number | undefined }
): PromiseAction<JournalEatableRecord> => ({
  type: UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT,
  payload: coreBackendService.updateJournalEatableRecord(recordUpdates),
  meta: {
    data: recordUpdates,
    ...oldValues,
    triggerDefaultSuccessToast: true
  }
})

export const postJournalEatableRecordAction = (
  quantifiedEatable: QuantifiedEatable,
  mealCategory: MealCategory,
  date: Date = today,
  source: string
): PromiseAction<JournalEatableRecord> => ({
  type: ADD_JOURNAL_EATABLE_RECORD,
  payload: coreBackendService.addJournalEatableRecord(quantifiedEatable, mealCategory, date, source),
  meta: {
    triggerDefaultSuccessToast: true,
    data: { id: `tmp-${Date.now()}`, quantifiedEatable, mealCategory, datetime: date, source }
  }
})

export const postOrPatchJournalEatableRecordAction = (
  quantifiedEatable: QuantifiedEatable,
  mealCategory: MealCategory,
  date: Date = today,
  source: string | undefined
): ThunkAction => async (dispatch, getState) => {
  const {
    journalEatableRecords: { data }
  } = getState()
  let journalEatableRecord = findJournalEatableRecord(data, date, mealCategory, quantifiedEatable)
  let mergedAmount: Amount | null = null

  if (!journalEatableRecord) {
    const { value } = await dispatch(fetchJournalEatableRecordsAction(date))

    journalEatableRecord = findJournalEatableRecord(value, date, mealCategory, quantifiedEatable)
  }

  if (journalEatableRecord) {
    mergedAmount = Utils.mergeEatableAmount(quantifiedEatable, journalEatableRecord)
    if (!mergedAmount) {
      console.error('The conversion of eatable amount can not be done')
    }
  }

  if (journalEatableRecord && mergedAmount) {
    const roundedAmount: Amount = {
      ...mergedAmount,
      quantity: Calculator.round(mergedAmount.quantity, 2)
    }
    return dispatch(
      updateJournalEatableRecordAction(
        { id: journalEatableRecord.id, ...roundedAmount },
        { oldUnit: journalEatableRecord.unit, oldQuantity: journalEatableRecord.quantity }
      )
    )
  } else {
    return dispatch({
      type: ADD_JOURNAL_EATABLE_RECORD,
      payload: coreBackendService.addJournalEatableRecord(quantifiedEatable, mealCategory, date, source),
      meta: {
        triggerDefaultSuccessToast: true,
        data: { id: `tmp-${Date.now()}`, quantifiedEatable, mealCategory, datetime: date, source }
      }
    })
  }
}

export const removeJournalEatableRecordAction = (id: string): ThunkAction => async (dispatch, getState) => {
  const {
    journalEatableRecords: { data: records }
  } = getState()

  const data = records.find((r) => r.id === id)

  if (data) {
    return dispatch({
      type: REMOVE_JOURNAL_EATABLE_RECORD,
      payload: coreBackendService.deleteJournalEatableRecord(id),
      meta: {
        triggerDefaultSuccessToast: true,
        data
      }
    })
  }
}

// Selectors
export const journalEatableRecordsSelector = ({
  journalEatableRecords
}: ReduxStoreState): JournalEatableRecordsReduxState => journalEatableRecords

export const journalEatableRecordsDataSelector = ({ journalEatableRecords }: ReduxStoreState): JournalEatableRecord[] =>
  journalEatableRecords.data

export const dayJournalEatableRecordsSelector = createSelector(
  [currentJournalDateSelector, journalEatableRecordsDataSelector],
  (currentJournalDate, journalEatableRecords) =>
    journalEatableRecords.filter((journalRecord) =>
      journalRecord.datetime ? dayjs(currentJournalDate).isSame(journalRecord.datetime, 'day') : false
    )
)

const asyncActionTypes = [
  FETCH_JOURNAL_EATABLE_RECORDS,
  ADD_JOURNAL_EATABLE_RECORD,
  REMOVE_JOURNAL_EATABLE_RECORD,
  UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT,
  UPDATE_JOURNAL_EATABLE_RECORD_DATETIME
]

export default combineReducers<JournalEatableRecordsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
